<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button :disabled="true" variant="primary">
          <input ref="refInputEl" type="file" class="d-none" />
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="3">
          <b-form-group label="Username" label-for="username">
            <b-form-input id="username" v-model="userData.username" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="3">
          <b-form-group label="Name" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="3">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col v-if="false" cols="12" md="4">
          <b-form-group label="Status" label-for="user-status">
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Groups -->
        <b-col cols="12" md="3">
          <b-form-group label="User Groups" label-for="user-groups">
            <v-select
              v-model="userData.groups"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groups"
              :reduce="reduc"
              :clearable="false"
              input-id="user-group"
              multiple
              :selectable="(item) => !item.disabled"
            >
              <template v-slot:option="option">
                <font-awesome-icon :icon="option.icon || 'fa-solid fa-user-group'" />
                &nbsp;{{ option.label }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Field: Compant -->
        <b-col cols="12" md="4">
          <b-form-group label="Avatar Url" label-for="avatar">
            <b-form-input id="avatar" v-model="userData.avatar" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <user-view-user-permissions-card v-if="false" :user-data="userData" />

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 ml-sm-1 float-right"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="processing"
      @click="saveChanges"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      class="float-right mb-1"
    >
      Reset
    </b-button>
    <b-button
      variant="outline-danger"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="resetPassword"
    >
      Reset Password
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import { initialAbility } from '@/libs/acl/config';
import vSelect from 'vue-select';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { onUnmounted, ref } from '@vue/composition-api';
import useUsersList from '../users-list/useUsersList';
import { title } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import UserViewUserPermissionsCard from '@/views/apps/user/users-view/UserViewUserPermissionsCard';
import store from '@/store';
import userStoreModule from '@/views/apps/user/userStoreModule';
import router from '@/router';

export default {
  components: {
    UserViewUserPermissionsCard,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      processing: false,
    };
  },
  methods: {
    resetPassword() {
      this.processing = true;

      this.$store
        .dispatch('app-user/resetUserPassword', {
          userId: this.userData.id,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `User Updated`,
              icon: 'UserIcon',
              variant: 'success',
              text: `A password reset has been sent to this user`,
            },
          });
        })
        .catch((error) => {
          if (error.response) {
            const { data } = error.response;

            if (data.message) {
              return this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to Update User',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: data.message,
                },
              });
            }
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Update User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'An unknown error has occurred.',
            },
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    reduc(val) {
      if (!val) return val;
      return val.value;
    },
    saveChanges() {
      this.processing = true;

      this.$http
        .patch(`users/${this.userData.id}`, {
          username: this.userData.username,
          name: this.userData.name,
          email: this.userData.email,
          groups: this.userData.groups,
          avatar: this.userData.avatar,
        })
        .then((response) => {
          this.processing = false;

          if (response.status === 200) {
            this.$router.push({ name: 'apps-users-view', params: { id: this.userData.id } });

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `User Updated`,
                icon: 'UserIcon',
                variant: 'success',
                text: `${this.userData.username}'s settings have been updated.`,
              },
            });
          }
        })
        .catch((error) => {
          this.processing = false;

          if (error.response) {
            const { data } = error.response;

            if (data.message) {
              return this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to Update User',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: data.message,
                },
              });
            }
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Update User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'An unknown error has occurred.',
            },
          });
        });
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];

    const groups = ref([]);

    store
      .dispatch('app-user/fetchGroups', { limit: 50 })
      .then((response) => {
        const { data } = response;
        console.log(data);
        groups.value = data.results
          .filter((group) => !group.hidden)
          .filter((group) => !group.default)
          .map((group) => {
            return { label: group.name, value: group.id, disabled: !group.enabled, icon: group.metadata.icon };
          });
      })
      .catch((err) => {
        console.error(err);
      });

    return {
      avatarText,
      statusOptions,
      groups,
      title,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
