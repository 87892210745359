<template>
  <b-form>
    <b-row>
      <b-col v-for="socialField in socialInputs" :key="socialField.dataField" cols="12" md="6" lg="4">
        <b-form-group :label="socialField.label" :label-for="socialField.dataField">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <font-awesome-icon :icon="socialField.icon" size="lg" />
            </b-input-group-prepend>
            <b-form-input :id="socialField.dataField" v-model="userDataSocial[socialField.dataField]" type="url" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="processing"
          @click="saveChanges"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="processing"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    userData: {
      type: Object,
      default: {},
    },
  },
  data: () => {
    return { processing: false };
  },
  methods: {
    saveChanges() {
      this.processing = true;

      if (this.userDataSocial.mondayId) {
        this.userDataSocial.mondayId = Number(this.userDataSocial.mondayId);
      }

      if (this.userDataSocial.dialPadId) {
        this.userDataSocial.dialPadId = Number(this.userDataSocial.dialPadId);
      }

      this.$http
        .patch(`users/${this.userData.id}`, { metadata: this.userDataSocial })
        .then((response) => {
          this.processing = false;

          if (response.status === 200) {
            this.$router.push({ name: 'apps-users-view', params: { id: this.userData.id } });

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `User Updated`,
                icon: 'UserIcon',
                variant: 'success',
                text: `${this.userData.username}'s social settings have been updated.`,
              },
            });
          }
        })
        .catch((error) => {
          this.processing = false;

          if (error.response) {
            const { data } = error.response;

            if (data.message) {
              return this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to Update User',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: data.message,
                },
              });
            }
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Update User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'An unknown error has occurred.',
            },
          });
        });
    },
  },
  setup(props) {
    const userDataSocial = { ...props.userData.metadata };

    // ? dataField is field name of `userDataSocial` object
    const socialInputs = [
      {
        icon: 'fa-brands fa-linkedin',
        dataField: 'linkedIn',
        label: 'LinkedIn',
      },
      {
        icon: 'fa-solid fa-phone',
        dataField: 'dialPadId',
        label: 'DialPad',
      },
      {
        icon: 'fa-solid fa-border-all',
        dataField: 'mondayId',
        label: 'Monday',
      },
    ];

    return {
      userDataSocial,
      socialInputs,
    };
  },
};
</script>

<style></style>
